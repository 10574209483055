.table .img-t1 {
  max-width: 300px;
}

.table-responsive {
  border: 0px solid rgb(221, 214, 214);
  border-radius: 5px;
  /* box-shadow: 2px 1px 2px 0px rgba(0,0,0,0.1);  */
  cursor: pointer;
}

.btn-secondary {
  box-shadow: none !important;
}

.btn-secondary-search {
  box-shadow: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.not-data-table {
  width: 100%;
  height: 100px;
  text-align: center;
}

tr .align-me {
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-title {
  text-align: right;
}

.dropdown-toggle::after {
  display: none !important;
}

@media screen and (max-width: 991px) {
  .table-braile .button-new {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .pagination {
    min-width: 70% !important;
  }
  .btn-group {
    min-width: 100% !important;
  }
}

.table-braile .btn-toolbar {
  margin-left: 15px !important;
}

.table-braile .row {
  width: 100% !important;
  text-align: right !important;
  align-items: baseline;
}
.color-background {
  background-color: rgb(214, 69, 44) !important;
}


/* .download-table-xls-button {
  padding: 0.375rem 0.75rem;
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
} */