body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hide {
  display: none !important;
}

.bg-colorbraile {
  --bs-bg-opacity: 1;
  background-color: rgba(13, 110, 253, 2) !important;
  background-color: rgb(214, 69, 44) !important;
}

.btn-brailebutton {
  color: aliceblue !important;
  background-color: #337ab7 !important;
}

.table-react-header {
  text-align: center;
}
